var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swiper-class",class:{ large: _vm.data.large }},[_c('swiper',{ref:"mySwiper",attrs:{"options":_vm.swiperOptions}},[_vm._l((_vm.data.slides),function(slide,index){return _c('swiper-slide',{key:index},[_c('div',{staticClass:"swiper-slide-inner",style:({
          backgroundSize: slide.fit || 'cover',
          backgroundPosition: slide.mediaAlignment
            ? slide.mediaAlignment.replace('_', ' ')
            : 'center center',
          color: slide.textcolor,
          backgroundImage: slide.media.data.attributes.mime.includes('image')
            ? ("url(" + (_vm.getUrl(
                slide.media.data,
                _vm.data.highresolution ? 3000 : 1500
              )) + ")")
            : null,
        }),attrs:{"data-swiper-parallax":"50%"}},[(slide.media.data.attributes.mime.includes('video'))?_c('video',{style:({
            objectPosition: slide.mediaAlignment
              ? slide.mediaAlignment.replace('_', ' ')
              : 'center center',
          }),attrs:{"src":slide.media.data.attributes.url,"loop":"","playsinline":""},domProps:{"muted":slide.mute}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"content container",style:({ textAlign: slide.textAlignment || 'center' })},[(slide.text)?_c('div',{ref:"render",refInFor:true,staticClass:"text",class:slide.textAlignment,domProps:{"innerHTML":_vm._s(_vm.$md.render(slide.text))}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"buttons container"},_vm._l((slide.buttons),function(button,index){return _c('nuxt-link',{key:'button' + index,attrs:{"to":_vm.getMenuSlug(button)}},[_c('div',{style:({
                  backgroundColor: slide.textcolor,
                  color: slide.buttontextcolor,
                })},[_vm._v("\n                "+_vm._s(button.title)+"\n              ")])])}),1)])])])}),_vm._v(" "),(_vm.data.arrows)?_c('div',{ref:"swiper-button-prev",staticClass:"swiper-button-prev",attrs:{"slot":"button-prev","id":'swiper-button-prev-' + _vm._uid},slot:"button-prev"}):_vm._e(),_vm._v(" "),(_vm.data.arrows)?_c('div',{ref:"swiper-button-next",staticClass:"swiper-button-next",attrs:{"slot":"button-next","id":'swiper-button-next-' + _vm._uid},slot:"button-next"}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"swiper-pagination",attrs:{"id":'swiper-pagination-' + _vm._uid}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }